<template>
    <div class="ant-spin-nested-loading">
        <div class="ant-spin-container">
            <div class="detail-content">
                <div class="detail-content-bottom">
                    <div class="ttp-site-header-wrapper">
                        <div class="ttp-site-header">
                            <a aria-label="今日头条徽标" class="logo" href="/" target="_blank" rel="nofollow"></a>
                            <div style="flex:1;min-width:40px"></div>
                           
                            <div class="header-notice-wrapper" style="flex-shrink:0">
                                <div aria-label="消息通知" class="ttp-header-notice" tabindex="0" role="button"
                                    aria-haspopup="true" aria-expanded="false"><a class="notice-icon"
                                        rel="nofollow">消息</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="thing-content-view">
                        <el-row :gutter="24" style="margin-top: 2%;">
                            <el-col :span="20">
                                <el-card class="box-card">
                                    <el-row>
                                        <el-col v-for="(item, index) in items" :key="index" class="thing-item"
                                            :span="24">
                                            <el-row align="middle">
                                                <div @click="navigateToToutiao(item.share_url)">
                                                    <el-col :span="6">
                                                        <img :src="item.middle_image.url" class="item-image" />
                                                    </el-col>
                                                    <el-col :span="18">
                                                        <div class="info-view">
                                                            <h3 class="thing-name">{{ item.title }}</h3>
                                                            <span class="details">
                                                                <span class="a-price">来源:{{ item.media_name }}</span>
                                                                <span class="a-price"
                                                                    style="margin-left: 10px;">时间:{{ convertTimestamp(item.publish_time)
                                                                    }}</span>
                                                                &nbsp; <span class="a-price">{{ item.date }}</span>
                                                            </span>
                                                        </div>
                                                    </el-col>
                                                </div>


                                            </el-row>
                                            <div style="border-bottom: 1px solid #eee;margin-top: 10px;"></div>
                                        </el-col>
                                    </el-row>
                                </el-card>
                            </el-col>
                            <el-col :span="4">
                                <el-card class="box-card">
                                    <div class="main-side">
                                        <div class="recommend" style="min-height: 300px;">
                                            <div class="title" style="">相关推荐</div>
                                            <div v-for="item in recommendedItems.slice(0, 5)" :key="item.id"
                                                class="thing-item">
                                                <div @click="navigateToToutiao(item.Url)">
                                                    <img class="img-view" :src="item.Image.url" />
                                                    <h3 class="thing-name">{{ item.Title }}</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </el-card>
                                <el-card class="box-card" style="margin-top: 10px;">
                                    <div class="main-side">
                                        <div class="recommend">
                                            <div class="title">广告</div>
                                            <div class="thing-item" v-for="ad in ads" :key="ad.id">
                                                <img class="img-view" :src="ad.image" />
                                            </div>
                                        </div>
                                    </div>
                                </el-card>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import { kejiListDetail, hotList } from "../../api/xinwen";
export default {
    name: 'Detail',
    data() {
        return {
            items: [],
            likes: 1,
            collections: 1,
            activeTab: '评论',
            newComment: '',
            info: '',
            recommendedItems: [],
            ads: [
                { id: 1, image: 'https://news.gitapp.cn/api/upload/ad/1720235370354.jpeg' },
                // 更多广告...
            ],
            id: '',
        }
    },
    created() {
        kejiListDetail().then(res => {
            if (res.data.code == 200) {
                this.items = res.data.data.data
                console.log("11111111", res.data.data)

                hotList().then(res => {
                    if (res.data.code == 200) {
                        this.recommendedItems = res.data.data.data
                        console.log(res.data.data.data)
                    }
                })
            }
        })
    },
    methods: {
        navigateToToutiao(url) {
            window.location.href = url;
        },
        convertTimestamp(time) {
            // 将时间戳转换为标准时间（UTC时间）
            const date = new Date(time * 1000);
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 月份是从0开始的
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            const seconds = String(date.getUTCSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        formatCreatedAt(createdAt) {
            // 使用 moment 解析时区并格式化时间
            return moment(createdAt).format('YYYY-MM-DD HH:mm:ss');
        },
    }
}
</script>
<style>
.ttp-site-header-wrapper {
    min-width: 1000px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 24px;
    width: 100%;
    height: 64px;
    background: #fff;
}

.ttp-site-header-wrapper .ttp-site-header {
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}
.ttp-site-header-wrapper .logo {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100px;
    height: 28px;
    background: url('../../assets/rb.png') no-repeat 50%;
    background-size: contain;
}

.ttp-header-notice {
    cursor: pointer;
    position: relative;
    outline: none;
}

.thing-item {
    margin-bottom: 20px;
}

.item-image {
    width: 50%;
    border-radius: 10px;
    /* 圆角 */
}

.ant-spin-nested-loading {
    position: relative;

    .ant-spin-container {
        position: relative;
        transition: opacity .3s;
    }

    .detail-content {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;

    }

    .thing-content-view {
        padding-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
    }

    .main-content {
        flex: 1;
        min-width: 300px;
        padding: 0 16px;
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 1px 2px #0000000d;
    }

    .title {
        text-align: center;
        font-size: 18px;
        color: rgb(17, 17, 17);
        font-weight: bold;
        margin: 20px 0px;
    }

    .source {
        text-align: center;
        font-size: 14px;
        color: rgb(17, 17, 17);
        margin: 20px 0px;
    }

    .description {
        margin: 30px 0px;
        color: rgb(51, 51, 51);
        background-color: rgb(255, 255, 255);
    }

    .content-image {
        width: 691px;
        height: 460.32px;
    }

}

.main-side .recommend .title {
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding-left: 16px;
    color: #152844;
    border-bottom: 1px solid #eee;
}

.main-side .recommend .thing-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 0;
    width: 85%;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
}

.main-side .recommend .thing-item .img-view {
    border-radius: 8px;
    width: 100%;
    aspect-ratio: 5/3;
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
}

.main-side .recommend .thing-item .thing-name {
    margin-top: 12px;
    color: #0f1111;
    font-size: 14px;
}
</style>